<template>
  <div class="flex-1 form w-full" :class="[variant]">
    <ClientOnly>
      <Vueform
        ref="form"
        validate-on="change|step"
        v-model="data"
        :schema="buildWithUsSection.form"
        @submit="handleSubmit"
        :display-messages="false"
        :size="variant === 'primary' ? 'md' : 'sm'"
      >
      </Vueform>
    </ClientOnly>
    <div class="flex items-center pt-4">
      <Button
        :aria-label="buildWithUsSection.formSubmitText"
        variant="default"
        dark
        class="flex gap-4 px-10 w-full bg-[#0B1E2D] hover:bg-[#0B1E2D]/80 text-white"
        @click="handleSubmit(data)"
        :disabled="loading"
      >
        <span>{{ buildWithUsSection.formSubmitText }}</span>
        <Icon v-if="loading" icon="eos-icons:bubble-loading"></Icon>
        <Icon v-else icon="mdi-arrow-right"></Icon
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { buildWithUsSection } from "~/lib/constants";
import { useReCaptcha } from "vue-recaptcha-v3";
import { toast } from "vue-sonner";
import axios from "axios";
import { useVfm } from "vue-final-modal";

defineProps({
  variant: {
    type: String as PropType<"primary" | "secondary">,
    default: "primary",
  },
});
const data = ref({});
const $captcha = useReCaptcha();
const loading = ref(false);
const tabs = ref({
  page0: {
    label: "Individual",
    elements: ["firstName", "lastName", "email", "phone", "country", "message"],
  },
  page1: {
    label: "Company",
    elements: [
      "firstName",
      "lastName",
      "email",
      "phone",
      "companyName",
      "message",
    ],
  },
});
const form = ref(null);

const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await $captcha?.recaptchaLoaded();

  // get the token, a custom action could be added as argument to the method
  const token = await $captcha?.executeRecaptcha("yourActionHere");

  return token;
};
const handleSubmit = async (data: any) => {
  const token = await recaptcha();

  const isValid = await (form.value as any).validate();
  if (!isValid) {
    const d = toRaw(data);
    switch (d.interest_type) {
      case "Products":
        d.interest = d.products;
        break;
      case "Services":
        d.interest = d.services;
        break;
      case "Other":
        d.interest = d.other;
        break;
    }
    delete d.products;
    delete d.services;
    delete d.other;
    d.token = token;

    try {
      loading.value = true;

      const req = await fetch(
        "https://erp.creativeadvtech.ml/api/method/creativeminds.api.website_leads.create_lead",
        {
          method: "POST",
          body: JSON.stringify(d),
          mode: "no-cors",
        }
      );

      toast.success("Form submitted successfully", {});
      data.value = {};
      useVfm().closeAll();
    } catch (e) {
      toast.error("Form submission failed", {});
    } finally {
      loading.value = false;
    }
  }
};
</script>

<style scoped lang="scss">
.form {
  //   :deep(.form-text-type) {
  //     // make parent of input and textarea background transparent
  //     .form-input-group {
  //       background-color: transparent;
  //       color: white;
  //       input,
  //       textarea {
  //         background-color: transparent;
  //         color: white;
  //         border-color: white;
  //       }
  //     }
  //   }
  &.primary {
    @apply text-white;
    :deep(.form-color-danger.block.form-text-small) {
      @apply text-red-500 bg-red-100 rounded-sm px-2;
    }
    :deep(.form-border-color-input-danger) {
      border-color: hsl(0, 100%, 74%);
    }
  }
  &.secondary {
    :deep(.form-text) {
      @apply text-gray-800;
    }
  }
  :deep(input) {
    &::placeholder {
      @apply text-gray-400;
    }
  }
  :deep(textarea) {
    &::placeholder {
      @apply text-gray-400;
    }
  }

  :deep(.form-color-placeholder) {
    @apply text-gray-400;
  }

  :deep(.label-floating) {
    display: none;
  }
}
</style>
